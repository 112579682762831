export function injectInputSpace(event: KeyboardEvent, currentText: string) {
  const target = event.target as HTMLInputElement
  const caretPos = target.selectionStart
  if (caretPos === null || caretPos === undefined)
    return currentText

  setTimeout(() => {
    if (!target.isConnected)
      return
    target.selectionStart = caretPos + 1
    target.selectionEnd = caretPos + 1
  }, 1)

  return [
    currentText.slice(0, caretPos),
    currentText.slice(caretPos),
  ].join(' ')
}

export function formatInvoiceType(type?: string | null) {
  switch (type) {
    case 'prepayment':
      return 'Facture d\'acompte'
    case 'credit_note':
      return 'Avoir'
    case 'invoice':
    default:
      return 'Facture'
  }
}
